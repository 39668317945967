import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  Dialog,
  DialogButton,
  Footer,
  ListItemText,
  Menu,
  Subheader,
  SubheaderText,
  useMenuOption,
} from '@ifca-ui/core'
import { AddBox, MoreVert, QrCodeScannerTwoTone } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import {
  CustomPhoneInput,
  formatPhoneNumber,
} from 'components/Inputs/CustomPhoneInput'
import { VoiceTextField } from 'components/Inputs/CustomTextfield'
import { NricTextField } from 'components/Inputs/NricTextField'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  JointBuyerInput,
  SaleStatus,
  useAddJointBuyerMutation,
  useEditJointBuyerMutation,
  useGetAllMasterDataQuery,
  useGetContactByIdLazyQuery,
  useGetCustomerOriginQuery,
  useGetGeneralTinByPartyLazyQuery,
  useGetPurchaserInfoListingQuery,
  useGetSaleContactListLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import {
  PurchaserInfoAddressDialog,
  addressProps,
} from './PurchaserInfoAddressDialog'

interface standardParameterProps {
  id: string
  name: string
  defaulted?: string
}

interface companyListProps {
  companyName: string
}

interface buyerDetailProps {
  id: string
  fullName: string
  email?: string
  phoneNo?: string
  gender?: string
  icNo?: string
  companyName?: string
  companyRegNo?: string
}

interface CustomerOriginSelect {
  id: string
  name: string
}

export interface PurchaserInfoProps {
  Nationality: standardParameterProps
  IdentityType: string
  IdentityNo: string
  Name?: buyerDetailProps | string
  Salutation: standardParameterProps
  Race: standardParameterProps
  MaritalStatus: standardParameterProps
  Gender: string
  CustomerOrigin: CustomerOriginSelect
  Email: string
  HomeTelNo: string
  MobileNo: string
  OfficeTelNo: string
  Remark: string
  Tin?: string
  SstNo?: string
  CompanyName?: companyListProps
  CompanyReg?: string
  DirectorName?: string
  mode: string
  bumiputera: boolean
  vip: boolean
  foreignPurchase: boolean
  sms: boolean
  whatsapp: boolean
  emailBlast: boolean
  purchaserApp: boolean
  addressList: addressProps[]
}

export const PurchaseInfoForm = (props: any) => {
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  let location = useLocation()

  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const { id, salesStatus, isMainBuyer, jointBuyerId } = location?.state
  const { mode } = props
  const { projectID, saleID }: any = useParams()

  const [record, setRecord] = useState<boolean>(false)
  const [ogAddr, setOGAddr] = useState(null)
  const [addrValue, setAddress] = useState(null)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [subContactList, setSubContactList] = useState([])

  const [CompanyPurchaseCheck, setCompanyPurchaseCheck] =
    useState<boolean>(false)

  const [SubscribeMarketingCheck, setSubscribeMarketingCheck] =
    useState<boolean>(false)
  const [radioState, setRadioState] = useState('')
  const [isSetDefault, setIsSetDefault] = useState(false)
  const [contactId, setContactId] = useState(null)
  const [dialogMode, setDialogMode] = useState<'Add' | 'Edit' | ''>('')
  const [existingContact, setExistingContact] = useState([])
  const [updatedSubContactList, setUpdatedSubContactList] = useState([])
  const [addressDialog, setAddressDialog] = React.useState(false)

  const [emailChecked, setEmailChecked] = React.useState(false)
  const [letterChecked, setLetterChecked] = React.useState(false)

  //#region schema validation
  const FormTemplateSchema = yup.object().shape({
    mode: yup.string(),
    IdentityType: CommonYupValidation.requireField('Identity Type is required'),
    IdentityNo: yup
      .string()
      .when(['mode', 'IdentityType'], (pageMode, value) => {
        switch (value) {
          case 'IDENTITY_CARD':
            return CommonYupValidation.NRIC()
            break
          case 'PASSPORT':
            return CommonYupValidation.requireField('Passport is required')
            break
          case 'MILITARY_CARD':
            return CommonYupValidation.requireField('Military Card is required')
            break
          case 'BUSINESS_LICENSE':
            return CommonYupValidation.requireField(
              'Business License is required'
            )
            break
          case '':
            return CommonYupValidation.NRIC()
            break
        }
      })
      .required('Identity No is required'),
    Name: !CompanyPurchaseCheck
      ? yup.mixed().nullable().required('Name is required')
      : null,
    CustomerOrigin: CommonYupValidation.requireArray(
      'Customer Origin is required'
    ),
    Email: yup
      .string()
      .required('Email Address is required')
      .email('Email Address is invalid'),
    MobileNo: CommonYupValidation.checkPhone(true, 'Mobile No. is required'),
    OfficeTelNo: CommonYupValidation.checkPhone(),
    HomeTelNo: CommonYupValidation.checkPhone(),
    CompanyName: !CompanyPurchaseCheck
      ? null
      : yup.mixed().nullable().required('Company Name is required'),
    CompanyReg: !CompanyPurchaseCheck
      ? null
      : CommonYupValidation.requireField('Company Reg No is required'),
    DirectorName: !CompanyPurchaseCheck
      ? null
      : CommonYupValidation.requireField(
          'Director Name is required'
        ).nullable(),
  })
  //#endregion
  //#region use form
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<PurchaserInfoProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FormTemplateSchema),
    defaultValues: {
      Nationality: null,
      IdentityType: '',
      IdentityNo: '',
      Name: null,
      Salutation: null,
      Race: null,
      MaritalStatus: null,
      Gender: '',
      CustomerOrigin: null,
      Email: '',
      HomeTelNo: '',
      MobileNo: '',
      OfficeTelNo: '',
      Remark: '',
      CompanyName: null,
      CompanyReg: '',
      DirectorName: '',
      mode: mode,
      addressList: [],
      bumiputera: false,
      emailBlast: false,
      foreignPurchase: false,
      purchaserApp: false,
      sms: false,
      vip: false,
      whatsapp: false,
      Tin: '',
    },
  })

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'addressList',
  })
  //#endregion

  //#region get project detail api
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })
  //#endregion

  //#region get master data api
  const {
    loading: getMasterDataLoading,
    error: getMasterDataError,
    data: { GetAllMasterInfo } = { GetAllMasterInfo: {} },
  } = useGetAllMasterDataQuery({})
  //#endregion

  //#region get sale contact list api
  const [
    getSaleContact,
    {
      data: { getSaleContactList } = { getSaleContactList: [] },
      loading: getSaleContactListLoading,
    },
  ] = useGetSaleContactListLazyQuery({
    onCompleted: data => {
      if (data.getSaleContactList) {
        const contactList = data?.getSaleContactList
        const tempList = contactList?.map(el => {
          return {
            ...el,
            label: `${el?.fullName} / ${el.icNo}`,
            companyLabel: `${el?.companyName} / ${el.companyRegNo}`,
          }
        })
        setSubContactList(tempList)
      }
    },
  })
  //#endregion

  //#region get all contact list graphql
  const [getContact, { data: { getContactById } = { getContactById: null } }] =
    useGetContactByIdLazyQuery({})

  const [
    getGeneralTin,
    { data: { getGeneralTinByParty } = { getGeneralTinByParty: null } },
  ] = useGetGeneralTinByPartyLazyQuery({
    onCompleted: data => {
      setValue('Tin', data?.getGeneralTinByParty?.tin)
    },
  })
  //#endregion

  //#region add purchaser graphql
  const [addPurchaser] = useAddJointBuyerMutation({
    onError: error => {},
    onCompleted: data => {
      setSnackBar(SystemMsgs.createNewRecord())
      navBack()
    },
  })
  //#endregion

  //#region edit purchaser
  const [editPurchaserApi] = useEditJointBuyerMutation({
    onError: error => {},
    onCompleted: data => {
      setSnackBar(SystemMsgs.updateRecord())
      navBack()
    },
  })
  //#endregion

  //#region get existing contact to filter out from contact listing
  const {
    refetch: getPurchaserInfoListingRefetch,
    data: { getPurchaserInfoListing } = { getPurchaserInfoListing: [] },
    loading: getPurchaserInfoListingLoading,
  } = useGetPurchaserInfoListingQuery({
    variables: {
      saleId: saleID,
    },
    onCompleted: data => {
      setExistingContact(data.getPurchaserInfoListing)
    },
  })
  //#endregion

  const {
    refetch: getCustomerOriginRefetch,
    data: { getCustomerOrigin: customerOriginList } = { getCustomerOrigin: [] },
    loading: getCustomerOriginLoading,
  } = useGetCustomerOriginQuery({})

  useEffect(() => {
    if (mode == 'edit') {
      getContact({
        variables: {
          contactId: id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getContactById &&
      mode != 'add' &&
      Object.keys(GetAllMasterInfo)?.length > 0
    ) {
      const nationalityObject = Nationalities.find(
        el => el.id == getContactById?.nationality?.id
      )

      const defaultSalutation = Salutations?.find(
        el => el.id == getContactById?.salutation?.id
      )
      const defaultRace = Races?.find(el => el.id == getContactById?.race?.id)
      const defaultMarital = MaritalStatus?.find(
        el => el.id == getContactById?.maritalStatus?.id
      )

      const contactExt = getContactById?.contactExt

      const curCustOrigin = customerOriginList?.find(
        x => x.id == contactExt?.customerOrigin
      )

      setValue('Nationality', nationalityObject ?? null)
      setValue('Salutation', defaultSalutation ?? null)
      setValue('Race', defaultRace ?? null)
      setValue('MaritalStatus', defaultMarital ?? null)
      setValue('IdentityType', contactExt?.identityType)
      setValue('IdentityNo', getContactById?.icNo)
      setValue('Email', getContactById?.email)
      setValue(
        'HomeTelNo',
        getContactById?.homeTel === null ? undefined : getContactById?.homeTel
      )
      setValue(
        'MobileNo',
        getContactById?.phoneNo === null ? undefined : getContactById?.phoneNo
      )
      setValue(
        'OfficeTelNo',
        getContactById?.officeTel === null
          ? undefined
          : getContactById?.officeTel
      )

      setValue('Gender', getContactById?.gender)
      setValue('CustomerOrigin', curCustOrigin)
      setValue('Remark', contactExt?.remark)
      setValue('Tin', contactExt?.tin)
      setValue('SstNo', contactExt?.sstNo)

      if (getContactById?.companyName && getContactById?.companyRegNo) {
        setCompanyPurchaseCheck(true)
        setValue('CompanyReg', getContactById?.companyRegNo)
      } else {
        setCompanyPurchaseCheck(false)
      }

      setValue('bumiputera', contactExt?.isBumi ?? false)
      setValue('vip', contactExt?.isVip ?? false)
      setValue('foreignPurchase', contactExt?.isForeignPurchase ?? false)

      setValue('sms', contactExt?.subscribeMaterialSms ?? false)
      setValue('whatsapp', contactExt?.subscribeMaterialWhatsapp ?? false)
      setValue('emailBlast', contactExt?.subscribeMaterialEmail ?? false)
      setValue('purchaserApp', contactExt?.subscribeMaterialPaNews ?? false)

      if (
        contactExt?.subscribeMaterialSms &&
        contactExt?.subscribeMaterialWhatsapp &&
        contactExt?.subscribeMaterialEmail &&
        contactExt?.subscribeMaterialPaNews
      ) {
        setSubscribeMarketingCheck(true)
      }

      if (contactExt?.mailingMethod == 'Email') {
        setRadioState('Email')
      }
      if (contactExt?.mailingMethod == 'Letter') {
        setRadioState('Letter')
      }
      if (contactExt?.mailingMethod == 'Email & Letter') {
        setRadioState('Email & Letter')
      }
      getContactById?.addressList?.map(el => {
        append({
          addressId: el.id,
          isPrimary: el.isPrimary,
          addressType: el.address.AddressType,
          address: el.address,
        })
      })
    }

    if (mode == 'add' && Object.keys(GetAllMasterInfo)?.length > 0) {
      const defaultSalutation = Salutations?.find(el => el.defaulted == true)
      const defaultRace = Races?.find(el => el.defaulted == true)
      const defaultMarital = MaritalStatus?.find(el => el.defaulted == true)

      if (mode == 'add') {
        if (defaultSalutation) {
          setValue('Salutation', defaultSalutation)
        }
        if (defaultRace) {
          setValue('Race', defaultRace)
        }
        if (defaultMarital) {
          setValue('MaritalStatus', defaultMarital)
        }

        setValue('HomeTelNo', '+60')
        setValue('MobileNo', '+60')
        setValue('OfficeTelNo', '+60')
      }
    }
  }, [getContactById, GetAllMasterInfo])

  useEffect(() => {
    if (subContactList?.length !== 0 && !isSetDefault && getContactById) {
      const nameObject = subContactList?.find(el => el.id == getContactById?.id)

      setContactId(nameObject?.id)
      if (nameObject) {
        if (!CompanyPurchaseCheck) setValue('Name', nameObject)

        if (CompanyPurchaseCheck) setValue('CompanyName', nameObject)

        if (CompanyPurchaseCheck) setValue('DirectorName', nameObject?.fullName)

        setIsSetDefault(true)
      }
    }
  }, [subContactList, CompanyPurchaseCheck, getContactById])

  //#region to exclude existing contact from contact list
  useEffect(() => {
    if (subContactList) {
      let excludeid = existingContact.map(item => item?.id)
      let updatedList = subContactList.filter(x => !excludeid.includes(x?.id))
      setUpdatedSubContactList(updatedList)
    }
  }, [subContactList])
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: isMainBuyer ? 'Main Buyer' : 'Joint Buyer',
          },
          rightText: '',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, getContactById])
  //#endregion

  useEffect(() => {
    getSaleContact({
      variables: {
        isCompany: CompanyPurchaseCheck,
      },
    })
  }, [CompanyPurchaseCheck])

  const assignTin = () => {
    const isForeign = watch('foreignPurchase')
    const custOrigin = watch('CustomerOrigin.id')
    let selectedTinParty: string = ''

    if (custOrigin === 'GOVERNMENT_STAFF') {
      selectedTinParty = 'Government'
      getGeneralTin({
        variables: {
          party: selectedTinParty,
        },
      })
    }
    if (isForeign) {
      selectedTinParty = 'ForeignBuyer'
      getGeneralTin({
        variables: {
          party: selectedTinParty,
        },
      })
    } else {
      setValue('Tin', '')
    }
  }

  //#region check subscribe marketing material
  const checkSubscribe = check => {
    setSubscribeMarketingCheck(check)
    setValue('sms', check)
    setValue('whatsapp', check)
    setValue('emailBlast', check)
    setValue('purchaserApp', check)
  }
  //#endregion

  //#region check all subscribe material is checked
  const checkAllSubscribe = () => {
    if (
      watch('sms') &&
      watch('whatsapp') &&
      watch('emailBlast') &&
      watch('purchaserApp')
    ) {
      setSubscribeMarketingCheck(true)
    } else {
      setSubscribeMarketingCheck(false)
    }
  }
  //#endregion

  const resetCompanyField = () => {
    reset()
    setSubscribeMarketingCheck(false)
    setRadioState('')
  }

  const onSubmit = (data: PurchaserInfoProps) => {
    if (mailingMethod === '') {
      setSnackBar('Please select at least one mailing method')
      return false
    }

    if (data.addressList?.length == 0) {
      setSnackBar(SystemMsgs.multipleAddress())
      return
    }

    if (!data?.addressList?.find(el => el.isPrimary)) {
      setSnackBar(SystemMsgs.correspondenceAddress())
      return
    }

    const checkingAddressList = data?.addressList?.map((x, index, array) => {

      const previousAddressId = index === 0 ? x?.addressId : array[0].addressId;

      if (index !== 0 && x?.addressId === previousAddressId) {
          return { ...x, addressId: null };
      }
      return x;
  });
    

    const existingId =
      mode != 'add' ? { ID: id, jointBuyerId: jointBuyerId } : null

    let mobileNo = formatPhoneNumber(data?.MobileNo)

    const submitData: JointBuyerInput = {
      companyPurchase: CompanyPurchaseCheck,
      fullName: CompanyPurchaseCheck
        ? data.DirectorName
        : typeof data.Name === 'object'
          ? data.Name.fullName
          : data.Name,
      email: data.Email,
      phoneNo: mobileNo,
      // DOB: Date;
      icNo: data.IdentityNo,
      homeTel: formatPhoneNumber(data?.HomeTelNo),
      officeTel: formatPhoneNumber(data?.OfficeTelNo),
      companyName: CompanyPurchaseCheck
        ? typeof data.CompanyName === 'object'
          ? data.CompanyName.companyName
          : data.CompanyName
        : null,
      companyRegNo: CompanyPurchaseCheck ? data.CompanyReg : null,
      race: data?.Race?.id,
      salutation: data?.Salutation?.id,
      maritalStatus: data?.MaritalStatus?.id,
      nationality: data?.Nationality?.id,
      gender: data?.Gender,
      // incomeRange: data?.
      identityType: data?.IdentityType,
      customerOrigin: data?.CustomerOrigin?.id,
      isBumi: data?.bumiputera,
      isVip: data?.vip,
      isForeignPurchase: data?.foreignPurchase,
      subscribeMaterialSms: data?.sms,
      subscribeMaterialWhatsapp: data?.whatsapp,
      subscribeMaterialEmail: data?.emailBlast,
      subscribeMaterialPaNews: data?.purchaserApp,
      mailingMethod: mailingMethod,
      remark: data?.Remark,
      tin: data?.Tin,
      sstNo: data?.SstNo,
      addressListing: checkingAddressList.map(x => {
        return {
          address: {
            AddressType: x?.address?.AddressType,
            address: x?.address?.address,
            city: x?.address?.city,
            country: x?.address?.country,
            postCode: x?.address?.postCode,
            state: x?.address?.state,
          },
          isPrimary: x?.isPrimary,
          addressId: x?.addressId || null,
        }
      }),
    }

    if (mode == 'add') {
      addPurchaser({
        variables: {
          input: submitData,
          saleId: saleID,
        },
      })
    } else {
      editPurchaserApi({
        variables: {
          input: {
            ...submitData,
            isMainBuyer: isMainBuyer,
            jointBuyerId: jointBuyerId,
          },
          saleId: saleID,
        },
      })
    }

    // if (!submitData?.AddressMultiple) {
    //   setSnackBar(SystemMsgs.multipleAddress())
    // } else {
    //   let temp = submitData?.AddressMultiple.filter(x => x?.is_primary === true)
    //   if (temp === undefined || temp?.length === 0 || temp === null) {
    //     setSnackBar(SystemMsgs.correspondenceAddress())
    //   } else {
    //     if (mode != 'add') {
    //       editPurchaserApi({
    //         variables: {
    //           input: submitData,
    //           saleId: saleID,
    //         },
    //       })
    //     } else {

    //     }
    //   }
    // }
  }

  //#region enum listing start
  const IdentityTypeListing = [
    {
      ID: 'IDENTITY_CARD',
      Name: 'Identity Card',
    },
    { ID: 'PASSPORT', Name: 'Passport' },
    { ID: 'MILITARY_CARD', Name: 'Military Card' },
    { ID: 'BUSINESS_LICENSE', Name: 'Business License' },
  ]

  const CustomerOriginListing = [
    { ID: 'STAFF_PURCHASE', Name: 'Staff Purchaser' },
    { ID: 'PUBLIC', Name: 'Public' },
    { ID: 'GOVERNMENT_STAFF', Name: 'Government Staff' },
    { ID: 'COMPANY_PURCHASE', Name: 'Company Purchase' },
  ]

  const { Genders, MaritalStatus, Races, Salutations, Nationalities }: any =
    GetAllMasterInfo
  //#endregion

  const triggerChange = isCompany => {
    getSaleContact({
      variables: {
        isCompany: isCompany,
      },
    })
  }

  const setDetails = data => {
    const nationality = Nationalities?.find(x => x.id == data?.nationality?.id)
    const salutation = Salutations?.find(x => x.id == data?.salutation?.id)
    const race = Races?.find(x => x.id == data?.race?.id)
    const maritalSataus = MaritalStatus?.find(
      x => x.id == data?.maritalStatus?.id
    )
    if (data?.companyName !== null) {
      setValue('DirectorName', data?.fullName)
      setValue('CompanyName', data)
    }

    if (typeof data === 'object' && data !== null) {
      setValue('Name', data)
      setValue('IdentityType', data?.contactExt?.identityType)
      setValue('IdentityNo', data?.icNo)
      setValue('Nationality', nationality)
      setValue('Salutation', salutation)
      setValue('Race', race)
      setValue('MaritalStatus', maritalSataus)
      setValue('Gender', data?.gender)
      setValue('CustomerOrigin', data?.contactExt?.customerOrigin)
      setValue('Email', data?.email)
      setValue('HomeTelNo', data?.homeTel)
      setValue('MobileNo', data?.phoneNo)
      setValue('OfficeTelNo', data?.officeTel)
      setValue('CompanyReg', data?.companyRegNo)
      setValue('bumiputera', data?.contactExt?.isBumi ?? false)
      setValue('foreignPurchase', data?.contactExt?.isForeignPurchase ?? false)
      setValue('vip', data?.contactExt?.isVip ?? false)
      setValue('sms', data?.contactExt?.subscribeMaterialSms ?? false)
      setValue('whatsapp', data?.contactExt?.subscribeMaterialWhatsapp ?? false)
      setValue('emailBlast', data?.contactExt?.subscribeMaterialEmail ?? false)
      setValue(
        'purchaserApp',
        data?.contactExt?.subscribeMaterialPaNews ?? false
      )

      if (data?.contactExt?.mailingMethod == 'Email') {
        setRadioState('Email')
      }
      if (data?.contactExt?.mailingMethod == 'Letter') {
        setRadioState('Letter')
      }
      if (data?.contactExt?.mailingMethod == 'Email & Letter') {
        setRadioState('Email & Letter')
      }
      setValue('Remark', data?.contactExt?.remark)
      setValue('Tin', data?.contactExt?.tin)
      setValue('SstNo', data?.contactExt?.sstNo)
    }

    if (data?.address?.address) {
      let array_of_address = [data?.address.address] || []

      setOGAddr(array_of_address) //to compare if there is any changes
      setAddress(array_of_address?.slice(0))
    }
  }

  //#region delete dialog

  const [deleteDialog, setDeleteDialog] = React.useState(false)

  const DeleteDialog = (
    <Dialog
      fullWidth={true}
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Address
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    Delete
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          <Box width="100%" display="flex">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              Are you sure want to delete this address?
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'10px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.AddressType}
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'5px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.address} {menu?.data?.address?.city}{' '}
              {menu?.data?.address?.country}
            </Typography>
          </Box>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => handleDeleteAddress(menu?.index),
          },
        ],
      }}
    />
  )
  //#enderegion

  const handleDeleteAddress = index => {
    setDeleteDialog(false)
    remove(index)
  }

  //hande mailing method checkbox
  const mailingMethod = useMemo(() => {
    if (emailChecked && letterChecked) {
      return 'Email & Letter'
    } else if (emailChecked) {
      return 'Email'
    } else if (letterChecked) {
      return 'Letter'
    } else {
      return ''
    }
  }, [emailChecked, letterChecked])

  useEffect(() => {
    if (radioState === 'Email & Letter') {
      setEmailChecked(true)
      setLetterChecked(true)
    } else if (radioState === 'Email') {
      setEmailChecked(true)
    } else if (radioState === 'Letter') {
      setLetterChecked(true)
    }
  }, [radioState])

  //endregion

  return (
    <>
      {DeleteDialog}
      <PurchaserInfoAddressDialog
        open={addressDialog}
        setOpen={setAddressDialog}
        dialogMode={dialogMode}
        addressList={watch('addressList')}
        menu={menu?.data}
        update={update}
        append={append}
        index={menu?.index}
      />

      <form id="submit-form">
        <Container>
          <Card>
            <Typography component="div" display={'flex'} width={'100%'}>
              <Typography component="div" width={'50%'}>
                <Typography
                  color="common.black"
                  component="span"
                  flex="1 1"
                  className="text-desc"
                  variant="inherit"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="CompanyPurchase"
                        color="primary"
                        disabled={
                          mode == 'add' || (mode == 'edit' && !isMainBuyer)
                        }
                        onChange={e => {
                          setCompanyPurchaseCheck(e.target.checked)
                          resetCompanyField()
                          triggerChange(e.target.checked)
                        }}
                        checked={CompanyPurchaseCheck}
                      />
                    }
                    label="Company Purchase"
                  />
                </Typography>
              </Typography>
              <Typography component="div" width={'50%'} textAlign={'right'}>
                <Typography
                  component="span"
                  variant="inherit"
                  className="text-desc"
                  color="primary"
                >
                  <DialogButton
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Scan ID <QrCodeScannerTwoTone />
                  </DialogButton>
                </Typography>
              </Typography>
            </Typography>
          </Card>
          {mode != 'add' && salesStatus != SaleStatus.Booked && (
            <Card className="card-container-detail">
              <Box className="left">
                <Typography className="label-f">Nationality</Typography>
                <Typography className="value-f">
                  {getContactById?.nationality?.name ?? '-'}
                </Typography>
              </Box>

              <Box className="right">
                <Typography className="label-f">
                  {getContactById?.companyName
                    ? 'Director Name'
                    : 'Name as per NRIC'}
                </Typography>
                <Typography className="value-f">
                  {getContactById?.fullName}
                </Typography>
              </Box>

              <Box className="left">
                <Typography className="label-f">Email</Typography>
                <Typography className="value-f">
                  {getContactById?.email ?? '-'}
                </Typography>
              </Box>

              <Box className="right">
                <Typography className="label-f">Identity Type</Typography>
                <Typography className="value-f">
                  {IdentityTypeListing?.find(
                    el => el.ID == getContactById?.contactExt?.identityType
                  )?.Name ?? '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className="label-f">
                  {!CompanyPurchaseCheck ? 'Identity No' : 'Director NRIC'}
                </Typography>
                <Typography className="value-f">
                  {getContactById?.icNo ?? '-'}
                </Typography>
              </Box>
            </Card>
          )}

          <Card>
            {CompanyPurchaseCheck && (
              <Controller
                control={control}
                name="CompanyName"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={subContactList}
                    freeSolo
                    getOptionLabel={option =>
                      option?.['companyLabel'] || option
                    }
                    onChange={(event, value) => {
                      onChange(value)
                      setContactId(value?.id ?? null)
                      setDetails(value)
                      setValue('addressList', [])
                      value?.addressList?.map(el => {
                        append({
                          ...el,
                          addressId: el.id,
                        })
                      })
                    }}
                    onInputChange={(e, value) => {
                      if (e) {
                        onChange(value)
                      }
                    }}
                    value={value?.['companyName'] ?? value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Company Name"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        value={value}
                        helperText={errors?.CompanyName?.message}
                        error={errors?.CompanyName ? true : false}
                        required
                      />
                    )}
                  />
                )}
              />
            )}

            {CompanyPurchaseCheck && (
              <Controller
                control={control}
                name="CompanyReg"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="CompanyReg"
                    label="Company Reg No."
                    required
                    value={value}
                    helperText={errors?.CompanyReg?.message}
                    error={errors?.CompanyReg ? true : false}
                  />
                )}
              />
            )}

            {CompanyPurchaseCheck && (
              <Controller
                control={control}
                name="DirectorName"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="DirectorName"
                    label="Director Name"
                    required
                    value={value}
                    helperText={errors?.DirectorName?.message}
                    error={errors?.DirectorName ? true : false}
                  />
                )}
              />
            )}

            {!CompanyPurchaseCheck && (
              <Controller
                control={control}
                name="Name"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={updatedSubContactList}
                    freeSolo
                    getOptionLabel={option => option?.['label'] || option}
                    onChange={(event, value) => {
                      onChange(value)
                      setContactId(value?.id ?? null)
                      setDetails(value)
                      setValue('addressList', [])
                      value?.addressList?.map(el => {
                        append({
                          ...el,
                          addressId: el.id,
                        })
                      })
                    }}
                    onInputChange={(e, value) => {
                      if (e) {
                        onChange(value)
                      }
                    }}
                    value={value?.['fullName'] ?? value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Name as per NRIC"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        value={value}
                        helperText={errors?.Name?.message}
                        error={errors?.Name ? true : false}
                        required
                        style={{
                          display:
                            mode == 'add' || salesStatus == SaleStatus.Booked
                              ? 'inline-flex'
                              : 'none',
                        }}
                      />
                    )}
                  />
                )}
              />
            )}

            <Controller
              control={control}
              name="Nationality"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  options={Nationalities}
                  getOptionLabel={option => option?.['name']}
                  fullWidth
                  value={value}
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Nationality"
                      // required
                      variant="standard"
                      margin="normal"
                      value={value}
                      autoComplete="off"
                      style={{
                        display:
                          mode == 'add' || salesStatus == SaleStatus.Booked
                            ? 'inline-flex'
                            : 'none',
                      }}
                    />
                  )}
                />
              )}
            />

            <Box
              display={
                mode == 'add' || salesStatus == SaleStatus.Booked
                  ? 'flex'
                  : 'none'
              }
              width="100%"
            >
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="IdentityType"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      margin="normal"
                      onBlur={onBlur}
                      onChange={onChange}
                      autoComplete="off"
                      name="IdentityType"
                      label="Identity Type"
                      required
                      value={value}
                      helperText={errors?.IdentityType?.message}
                      error={errors?.IdentityType ? true : false}
                    >
                      {IdentityTypeListing?.map(v => (
                        <MenuItem value={v.ID}>{v.Name}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                {watch('IdentityType') == 'IDENTITY_CARD' ||
                !watch('IdentityType') ? (
                  <Controller
                    control={control}
                    name="IdentityNo"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NricTextField
                        label={
                          !CompanyPurchaseCheck
                            ? 'Identity No'
                            : 'Director NRIC'
                        }
                        name="IdentityNo"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        helperText={errors?.IdentityNo?.message}
                        error={errors?.IdentityNo ? true : false}
                        required={true}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="IdentityNo"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        variant="standard"
                        fullWidth
                        margin="normal"
                        onBlur={onBlur}
                        onChange={onChange}
                        autoComplete="off"
                        name="IdentityNo"
                        label={
                          IdentityTypeListing?.find(
                            el => el.ID == watch('IdentityType')
                          )?.Name
                        }
                        required
                        value={value}
                        helperText={errors?.IdentityNo?.message}
                        error={errors?.IdentityNo ? true : false}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>

            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Salutation"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={Salutations}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      value={value}
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Salutation"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Race"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={Races}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Race"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          // helperText={errors?.Race?.message}
                          // error={errors?.Race ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="MaritalStatus"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={MaritalStatus}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Marital Status"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          // helperText={errors?.MaritalStatus?.message}
                          // error={errors?.MaritalStatus ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Gender"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      margin="normal"
                      onBlur={onBlur}
                      onChange={onChange}
                      autoComplete="off"
                      name="Gender"
                      label="Gender"
                      // required
                      value={value}
                      // helperText={errors?.Gender?.message}
                      // error={errors?.Gender ? true : false}
                    >
                      {Genders &&
                        Object.keys(Genders)?.map(el => (
                          <MenuItem value={el}>{Genders[el]}</MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            <Controller
              control={control}
              name="CustomerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  fullWidth
                  options={customerOriginList}
                  getOptionLabel={option => option?.['name']}
                  onChange={(event, newValue) => {
                    onChange(newValue)
                    assignTin()
                  }}
                  getOptionDisabled={option => {
                    if (
                      option.id === 'GOVERNMENT_STAFF' &&
                      watch('foreignPurchase') == true
                    ) {
                      return true
                    }
                    return false
                  }}
                  value={value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Customer Origin"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                      value={value}
                      helperText={errors?.CustomerOrigin?.message}
                      error={errors?.CustomerOrigin ? true : false}
                      required
                    />
                  )}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="CustomerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  select
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={value => {
                    onChange(value)
                    assignTin()
                  }}
                  autoComplete="off"
                  name="CustomerOrigin"
                  label="Customer Origin"
                  required
                  value={value}
                  helperText={errors?.CustomerOrigin?.message}
                  error={errors?.CustomerOrigin ? true : false}
                >
                  {CustomerOriginListing?.map(v => (
                    <MenuItem value={v.ID}>{v.Name}</MenuItem>
                  ))}
                </TextField>
              )}
            /> */}

            <Box width="100%" display="flex">
              <Box width="50%" pr="6px">
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="bumiputera"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          color="primary"
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  }
                  label="Bumiputera"
                />
              </Box>

              <Box width="50%" pr="6px">
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="vip"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          color="primary"
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  }
                  label="VIP"
                />
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="foreignPurchase"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      color="primary"
                      onChange={(event, value) => {
                        onChange(event.target.checked)
                        assignTin()
                      }}
                      checked={value}
                      onBlur={onBlur}
                      disabled={
                        watch('CustomerOrigin')?.id === 'GOVERNMENT_STAFF'
                          ? true
                          : false
                      }
                    />
                  )}
                />
              }
              label="Foreign Purchase"
            />

            <Controller
              control={control}
              name="Email"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="Email"
                  label="Email"
                  required
                  value={value}
                  helperText={errors?.Email?.message}
                  error={errors?.Email ? true : false}
                  // style={{
                  //   display:
                  //     salesStatus == SaleStatus.Booked ? 'inline-flex' : 'none',
                  // }}
                />
              )}
            />

            <Box width="100%" display="flex">
              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="HomeTelNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomPhoneInput
                      onChange={onChange}
                      value={value}
                      label="Home Tel No."
                      error={!!errors?.HomeTelNo}
                      errorText={errors?.HomeTelNo?.message}
                    />
                  )}
                />
              </Box>

              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="MobileNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomPhoneInput
                      onChange={onChange}
                      value={value}
                      label="Mobile No."
                      required
                      error={!!errors?.MobileNo}
                      errorText={errors?.MobileNo?.message}
                    />
                  )}
                />
              </Box>
            </Box>

            <Controller
              control={control}
              name="OfficeTelNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomPhoneInput
                  onChange={onChange}
                  value={value}
                  label="Office Tel No."
                  error={!!errors?.OfficeTelNo}
                  errorText={errors?.OfficeTelNo?.message}
                />
              )}
            />

            <Box width="100%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="SubscribeMarketing"
                    color="primary"
                    onChange={e => checkSubscribe(e.target.checked)}
                    checked={SubscribeMarketingCheck}
                  />
                }
                label="Subscribe Marketing Material"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="sms"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          onChange(e.target.checked)
                          checkAllSubscribe()
                        }}
                        checked={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                }
                label="SMS"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="whatsapp"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          onChange(e.target.checked)
                          checkAllSubscribe()
                        }}
                        checked={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                }
                label="Whatsapp"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="emailBlast"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          onChange(e.target.checked)
                          checkAllSubscribe()
                        }}
                        checked={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                }
                label="Email Blast"
              />
            </Box>

            <Box width="100%" marginLeft="2%">
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="purchaserApp"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        color="primary"
                        onChange={e => {
                          onChange(e.target.checked)
                          checkAllSubscribe()
                        }}
                        checked={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                }
                label="Purchaser App > News"
              />
            </Box>

            <Box>
              <Typography
                className="text-xlTitle"
                color="common.black"
                component="div"
                flex="1 1"
                variant="inherit"
              >
                Mailing Method *
              </Typography>
              {/* <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={radioState}
                >
                  <FormControlLabel
                    value="Email"
                    control={<Radio />}
                    onChange={() => setRadioState('Email')}
                    label={'Email'}
                  />

                  <FormControlLabel
                    value="Letter"
                    control={<Radio />}
                    onChange={() => setRadioState('Letter')}
                    label={'Letter'}
                  />
                </RadioGroup>
              </FormControl> */}
              <FormControl fullWidth>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={emailChecked}
                        onChange={() => setEmailChecked(!emailChecked)}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={letterChecked}
                        onChange={() => setLetterChecked(!letterChecked)}
                      />
                    }
                    label="Letter"
                  />
                </FormControl>
              </FormControl>
            </Box>

            <Controller
              control={control}
              name="Remark"
              render={({ field: { onChange, onBlur, value } }) => (
                <VoiceTextField
                  mounted={true}
                  label="Remark"
                  name="Remark"
                  required={false}
                  setValue={setValue}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  record={record}
                  setRecord={setRecord}
                  controllerProps={{
                    error: !!errors?.Remark,
                    helperText: errors?.Remark?.message,
                    control: control,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="Tin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="Tin"
                  label="TIN"
                  // required
                  value={value}
                  InputProps={{
                    readOnly:
                      watch('CustomerOrigin.id') === 'GOVERNMENT_STAFF'
                        ? true
                        : false,
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="SstNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="SstNo"
                  label="SST Registration No"
                  // required
                  value={value}
                  // helperText={errors?.Email?.message}
                  // error={errors?.Email ? true : false}
                  // style={{
                  //   display:
                  //     salesStatus == SaleStatus.Booked ? 'inline-flex' : 'none',
                  // }}
                />
              )}
            />
          </Card>

          {/* <Card> */}
          <div className="addres-card-list">
            <CardListHeader
              title={
                <Typography
                  className="text-xsTitle"
                  color="#FF9800"
                  component="span"
                  width="100%"
                  flex="1 1"
                  variant="inherit"
                >
                  Address Listing
                </Typography>
              }
              action={
                <IconButton
                  aria-label="Addbox"
                  style={{
                    padding: '0px',
                  }}
                  onClick={() => {
                    if (fields?.length < 4) {
                      setAddressDialog(true)
                      setDialogMode('Add')
                    } else {
                      setSnackBar(SystemMsgs.addressLimit())
                    }
                  }}
                >
                  <AddBox color="primary" />
                </IconButton>
              }
            >
              {fields.length === 0 ? (
                <ListItemText
                  className="listingPadding"
                  primary={
                    <Typography
                      flex="1 1"
                      component="span"
                      variant="inherit"
                      className="text-xsTitle"
                    >
                      No Address Added, Click add button to add address
                    </Typography>
                  }
                />
              ) : (
                fields?.map((el, index) => (
                  <CardListItem
                    id={el.id}
                    itemButton={
                      <>
                        {/* <Switch
                          checked={activeListingIndex === index}
                          onChange={() =>
                            setActiveListingIndex(prevIndex =>
                              prevIndex === index ? null : index
                            )
                          }
                        /> */}
                        <IconButton
                          edge="end"
                          aria-label="more"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          style={{
                            padding: '0px 12px',
                          }}
                          onClick={e =>
                            handleClick(e, el?.address?.AddressType, index, el)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      </>
                    }
                    body={
                      <ListItemText
                        primary={
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              flex="1 1"
                              component="span"
                              variant="inherit"
                              className="text-xsTitle"
                            >
                              {el?.address?.AddressType}
                            </Typography>
                            <Typography
                              className="text-xsTitle"
                              color="primary"
                            >
                              {el?.isPrimary ? 'Primary' : ''}
                            </Typography>
                          </div>
                        }
                        secondary={
                          <Typography
                            flex="1 1"
                            component="span"
                            variant="inherit"
                            className="text-xsTitle"
                          >
                            {el?.address?.address} {el?.address?.city}{' '}
                            {el?.address?.country}
                          </Typography>
                        }
                      />
                    }
                  />
                ))
              )}
            </CardListHeader>
          </div>
          {/* </Card> */}

          <Menu
            id="menu-list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAddressDialog(true)
                setDialogMode('Edit')
              }}
            >
              <span className="">Edit</span>{' '}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </Menu>
          <Footer
            buttons={[
              {
                children: 'Save',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
              },
            ]}
          />
        </Container>
      </form>
    </>
  )
}
